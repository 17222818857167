import React, {useEffect, useRef, useState} from "react";
import {AgGridReact} from "ag-grid-react";
import {Button, IconButton, Menu, MenuItem} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {Delete} from "@mui/icons-material";
import exceljs from 'exceljs';
import {saveAs} from 'file-saver';
import {formatJewishDateInHebrew, toJewishDate} from 'jewish-date';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomFilter from "./filters/CustomFilter";
import {hebrewComparator, monthes} from "./utils/utils";
import useFetchWithAuth from "./hooks/useFetchWithAuth";
import CustomYearMonthFilter from "./filters/CustomYearMonthFilter";

const ITEM_HEIGHT = 48;

export const jewishDate = (date) => {
    const d = new Date(date);
    const pad = (num) => (num < 10 ? '0' + num : num);
    const hours = pad(d.getHours());
    const minutes = pad(d.getMinutes());
    const seconds = pad(d.getSeconds());

    const [year, month, day] = date.slice(0, date.indexOf("T")).split('-').map(Number);
    const jsDate = new Date(year, month - 1, day);
    return formatJewishDateInHebrew(toJewishDate(jsDate)) + `\t ${hours}:${minutes}:${seconds}`;
};

const AttandanceTable = () => {
    const [baseData, setBaseData] = useState([])
    const [rowData, setRowData] = useState([])
    const [filters, setFilters] = useState({})
    const [clearFilters, setClearFilters] = useState(false)
    const gridData = useRef()
    const fetchWithAuth = useFetchWithAuth();

    const dateComparator = (valueA, valueB) => {
        const dateA = new Date(valueA);
        const dateB = new Date(valueB);
        return dateB - dateA; // For descending order
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/attandance`, {
                    method: "GET",
                });

                if (response.ok) {
                    const data = await response.json();
                    const transformData = data.map(d => {
                        const hours = d.hours + (d.minutes / 60.0);
                        const hours2 = +hours.toFixed(2);
                        return ({
                            ...d,
                            student_tz: Number(d.student_tz),
                            hebDate: jewishDate(d.attendanceDate),
                            name: `${d.description} ${d.lastName} ${d.firstName}`,
                            framework: `כולל ${d.nameHe}`,
                            fullHours: (d.hours < 10 ? '0' : '') + d.hours + ':' + (d.minutes < 10 ? '0' : '') + d.minutes,
                            excelHours: hours2,
                            consecutivePeriods: d.consecutivePeriods !== 0 ? `${d.consecutivePeriods}` : ""
                        });
                    }).sort((a, b) => dateComparator(a, b));
                    setRowData(transformData);
                    setBaseData(transformData);
                }
            } catch (ex) {
                console.error("error on fetching data");
            }
        };
        fetchData();
    }, []);

   const paymentFilter = (year, month, row) => {
    const isLate = row['isLate'];
    const yearName = row['year'];
    const monthName = row['month'];
    const fieldToFilter = (isLate || `${monthName}, ${yearName}`).replace(/״/g, '');
    const [monthVal, yearVal] = fieldToFilter.split(', ');
    if (year.length && !year.includes(yearVal)) return false;
    return !(month.length && !month.includes(monthVal));
};

    const filtering = (field, filterValues, clear) => {
        if (clear) {
            setFilters({});
            setRowData(baseData);
            setClearFilters(false);
        } else {
            const notFieldFilter = Object.keys(filterValues).every(key => !filterValues[key]);

            const tempFilter = {
                ...filters,
                [field]: {filterValues}
            };

            if (notFieldFilter) {
                delete tempFilter[field];
            }

            setFilters(clear ? {} : tempFilter);
            const availableFilters = Object.keys(tempFilter);
            const filteredData = baseData.filter(data => {
                return availableFilters.every(f => {
                    if (f === "isLate") {
                        return (tempFilter[f].filterValues["מאוחר"] && data[f]) || (tempFilter[f].filterValues["רגיל"] && !data[f])
                    }
                    if(f === "payment") {
                       return  paymentFilter(tempFilter[f].filterValues.year, tempFilter[f].filterValues.month, data);
                    }
                    return tempFilter[f].filterValues[data[f]];
                });
            });
            setRowData(filteredData);
        }
    };

    const gridOptions = {
        enableRtl: true,
        defaultColDef: {
            sortable: true,
            filter: true,
        },
        sortModel: [{colId: 'תאריך', sort: 'desc'}]
    };

    function comparator(valueA, valueB, nodeA, nodeB) {
        const dateA = new Date(nodeA.data.attendanceDate);
        const dateB = new Date(nodeB.data.attendanceDate);
        return dateA - dateB;
    }

    const deleteAttandance = async (attandance) => {
        attandance.framework = attandance.framework_id;
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/attandance/delete-attendance`, {
                method: "POST",
                body: JSON.stringify(attandance)
            });

            if (response.ok) {
                const newData = rowData.filter(d => !(d.framework_id === attandance.framework_id && d.month === attandance.month && d.year === attandance.year && d.student_tz === attandance.student_tz));
                setRowData(newData);
            }
        } catch (ex) {
            console.error("error on fetching data");
        }
    };

    const DeleteComponent = (params) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <div>
                <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    className='icon-button'
                >
                    <MoreVertIcon/>
                </IconButton>
                <Menu
                    id="long-menu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                                direction: 'rtl'
                            }
                        }
                    }}
                >
                    <MenuItem onClick={() => {
                        handleClose();
                        deleteAttandance(params.data)
                    }} style={{direction: "rtl", height: "40px"}}>
                        <Delete></Delete>
                        <p style={{width: "100%", textAlign: "center"}}> מחיקה </p>
                    </MenuItem>
                </Menu>
            </div>
        );
    };

    const columnDefs = [
        {headerName: "", field: "delete", cellRenderer: DeleteComponent, width: "60"},
        {headerName: "ת.ז.", field: "student_tz", sortable: true, filter: true, width: 120},
        {headerName: "שם", field: "name", sortable: true, filter: true, width: 300, comparator: hebrewComparator},
        {headerName: "מסגרת", field: "framework", sortable: true, filter: true, width: 150},
        {headerName: "שנה", field: "year", sortable: true, filter: true, width: 100},
        {headerName: "חודש", field: "month", sortable: true, filter: true, width: 85},
        {headerName: "שעות", field: "fullHours", sortable: true, filter: true, width: 100},
        {headerName: "שעות לאקסל ", field: "excelHours", sortable: true, filter: true, width: 150},
        {headerName: "רצופות", field: "consecutivePeriods", sortable: true, filter: true, width: 100},
        {
            headerName: " תאריך",
            field: "hebDate",
            sortable: true,
            filter: true,
            width: 250,
            comparator: comparator,
            sort: 'desc'
        },
        {headerName: "מאוחר", field: "isLate", sortable: true, filter: true, width: 200},
        {
            headerName: "הערות",
            field: "comments",
            sortable: true,
            filter: true,
            width: 300,
            comparator: hebrewComparator
        },
    ];

    const convertToExcel = () => {
        const currentData = gridData.current.api.getModel().rowsToDisplay.map(rowNode => {
            const rowData = { ...rowNode.data };
            Object.keys(rowData).forEach(key => {
                if (rowData[key] === "") {
                    rowData[key] = null;
                }
            });
            return rowData;
        });
        const workbook = new exceljs.Workbook();
        const regularWorksheet = workbook.addWorksheet('דוחות רגילים');
        const lateWorksheet = workbook.addWorksheet('דוחות מאוחרים');

        const columns = [
            {header: 'ת.ז', key: 'student_tz', width: 10},
            {header: 'שם', key: 'name', width: 20},
            {header: 'מסגרת', key: 'framework', width: 10},
            {header: 'שנה', key: 'year', width: 10},
            {header: 'חודש', key: 'month', width: 20},
            {header: 'שעות', key: 'excelHours', width: 10, type: 'number'},
            {header: 'רצופות', key: 'consecutivePeriods', width: 20, type: 'number'},
            {header: 'תאריך דיווח', key: 'hebDate', width: 10},
            {header: 'מאוחר', key: 'isLate', width: 10},
            {header: 'הערות', key: 'comments', width: 30}
        ];

        regularWorksheet.columns = columns;
        lateWorksheet.columns = columns;

        currentData.filter(d => d.isLate).forEach(item => {
            lateWorksheet.addRow(item);
        });

        currentData.filter(d => !d.isLate).forEach(item => {
            regularWorksheet.addRow(item);
        });

        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            saveAs(blob, 'דוחות.xlsx');
        });
    };

    const years = [...new Set(baseData.map(d => d.year))].sort();
    const frameworks = [...new Set(baseData.map(d => d.framework))];

    return (
        <div>
            <div style={{marginBottom: "10px", display: "flex", justifyContent: "space-between", zIndex: 1}}>
                <div style={{display: "flex", zIndex: 2}}>

                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        height: '100px',
                        alignContent: 'flex-start',
                        justifyContent: 'space-evenly'
                    }}>
                        <div style={{'display': "flex", "position": "relative"}}>
                            <CustomFilter onFilterChanged={filtering} filterName={"בחר חודשים"} fieldName="month"
                                          filters={monthes} clearFilters={clearFilters}></CustomFilter>
                            <CustomFilter onFilterChanged={filtering} filterName={"בחר שנים"} fieldName="year"
                                          filters={years} clearFilters={clearFilters}></CustomFilter>
                            <CustomFilter onFilterChanged={filtering} filterName={"בחר סוג דיווח"} fieldName="isLate"
                                          filters={["מאוחר", "רגיל"]} clearFilters={clearFilters}></CustomFilter>
                            <CustomFilter onFilterChanged={filtering} filterName={"בחר מסגרת"} fieldName="framework"
                                          filters={frameworks} clearFilters={clearFilters}
                                          width={"200px "}></CustomFilter>
                        </div>
                        <div style={{display:"flex", alignItems: "center", justifyContent: 'space-between'}}>
                            <CustomYearMonthFilter onFilterChanged={filtering} filterName={"בחר חודש דיווח"}
                                                   fieldName="payment" filters={{months: monthes, years: years}}
                                                   clearFilters={clearFilters} width={"512px "} />
                            <Button style={{maxHeight: "30px", direction: "rtl", width: '163px'}} variant="contained"
                                    onClick={() => setClearFilters(true)} clearFilters={clearFilters}> נקה סינון</Button>
                        </div>
                    </div>
                </div>

                <Button style={{maxHeight: "30px", direction: "rtl", margin: "15px"}} variant="contained" onClick={convertToExcel}
                        clearFilters={clearFilters}> ייצא ל excel</Button>
            </div>
            <div className="ag-theme-alpine" style={{height: 'calc(100vh - 170px)', width: '100%', zIndex: 0}}>
                <AgGridReact enableCellTextSelection={true} ensureDomOrder={true} rowData={rowData}
                             columnDefs={columnDefs} ref={gridData} gridOptions={gridOptions}></AgGridReact>
            </div>
        </div>
    );
};

export default AttandanceTable;